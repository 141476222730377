<template>
  <div class="text-center mb-3">
    <h1 class="mb-3">Epic Road Trip Planner</h1>
    <p class="lead text-mute mb-4">Simple, free, easy-to-use tools for planning your next epic road trip.</p>
  </div>
  <div class="mb-3">
    <ERTPNewRoadtrip
      @update:model-value="onNewRoadtrip"
      :defaultOriginGpid="defaultOriginGpid"
      :defaultDestinationGpid="defaultDestinationGpid"
    />
  </div>
  <div class="mb-3">
    <ERTPRecentRoadtrips
      class="border border-rounded rounded-3 p-3"
      :max-items=5 
      v-show="userHasRoadtrips"
    />
  </div>
  <div v-show="isReady">      
    <ERTPSignUpEncouragement v-if="mustSignUpForMoreRoadtrips" class="mb-3"/>
    <ERTPSubscriptionEncouragement v-else-if="mustSubscribeForMoreRoadtrips" class="mb-3"/>
  </div>

  <ERTPResultMessage/>

</template>

<script setup lang="ts">
import ERTPRecentRoadtrips from "../../components/ERTPRecentRoadtrips.vue";
import ERTPNewRoadtrip from "../../components/ERTPNewRoadtrip.vue";
import ERTPSignUpEncouragement from "../../components/ERTPSignUpEncouragement.vue";
import ERTPSubscriptionEncouragement from "../../components/ERTPSubscriptionEncouragement.vue";

import { computed } from "vue";
import { GoogleService } from "../../services/googleService";
import { Roadtrip } from "../../models/roadtrip";
import { injectRequired } from "../../utilities/injectRequired";
import { useRoadtrips } from "../../composables/roadtrips";
import { navigate } from "vike/client/router";
import { useUserState } from "../../composables/userState";
import { useServices } from "../../composables/services";
import { usePageContext } from 'vike-vue/usePageContext';
import ERTPResultMessage from "../../components/ERTPResultMessage.vue";
import { useSubscriptionFunnel } from "../../composables/subscriptionFunnel";
import { useProfile } from "../../composables/profile";

const pageContext = usePageContext();

const googleService = injectRequired<GoogleService>('googleService');

const {
  profilesService,
  roadtripsService,
} = useServices();

const { userState } = useUserState();
const { profile } = useProfile();

const {
  roadtrips,
} = useRoadtrips();

const {
  mustSignUpForMoreRoadtrips,
  mustSubscribeForMoreRoadtrips
} = useSubscriptionFunnel(userState, profile, roadtrips);

const defaultDestinationGpid = getGooglePlaceId('ertp_destination_gpid')
const defaultOriginGpid = getGooglePlaceId('ertp_orig_gpid');

function getGooglePlaceId(queryParam: string): string | undefined {
  const placeIdParam = pageContext.urlParsed.search[queryParam];
  if (placeIdParam) {
    return placeIdParam.toString();
  }
}

const isReady = computed(() => {
  if (userState.value.loading) return false;
  if (googleService.isLoading.value) return false;
  if (roadtripsService.isLoading.value) return false;
  if (profilesService.isLoading.value) return false;
  return true;
});
 
const userHasRoadtrips = computed(() => (
  userState.value.isSignedIn 
  && roadtrips.value 
  && roadtrips.value.length
));

function onNewRoadtrip(roadtrip?: Roadtrip) {
  if (roadtrip?.link) navigate(roadtrip.link);  
}

</script>
