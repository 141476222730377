<template>
  <div class="border border-primary border-rounded rounded-3 pt-3">
    <h2 class="text-center mb-3" :class="!accepted ? 'opacity-25' : 'opacity-100'">New Road Trip</h2>
    <div class="d-flex align-items-center px-3 flex-column">
      <form>
        <ERTPStopInput
          location-label-text="Start Location"
          :placeholder="originPlaceholder"
          helpText="Start location and destination can be a city, an address, or a description of a location."
          :readOnly="originReadOnly"
          v-model:location="originLocation"
          v-model:gpid="originGooglePlaceId"
          :dateLabelText="originDateLabelText"
          v-model:dateTime="departureDateTime"
          :startTime="originStartTime"
          :timeZone="originTimezoneInfo?.timezone"
          date-picker-name='Departure Datepicker'
          date-picker-placeholder="(Optional) Planned departure date"
          datePickerHelpText="This is the date that you want to leave this location. You can leave this blank if you don't already have a specific departure date in mind."
        />
        <ERTPStopInput
          location-label-text="Destination"
          :placeholder="destinationPlaceholder"
          helpText="Where are you headed? You can add additional stops and other customization later."
          :readOnly="destinationReadOnly"
          v-model:location="destinationLocation"
          v-model:gpid="destinationGooglePlaceId"
          :dateLabelText="destinationDateLabelText"
          v-model:dateTime="arrivalDateTime"
          :startTime="destinationStartTime"
          :timeZone="destinationTimezoneInfo?.timezone"
          date-picker-name='Arrival Datepicker'
          date-picker-placeholder="(Optional) Expected arrival date"
          datePickerHelpText="This is the date on which you want to arrive at this destination. You can leave this blank if you don't have a specific arrival date in mind."
        />

        <ERTPCreateRoadtrip
          :origin="origin"
          :destination="destination"
          :can-create-roadtrip="canCreateRoadtrip"
          v-model:roadtrip="roadtrip"
          v-model:loading="loading"
        />

        <ERTPInputValidationMsg
          class="text-center"
          :input-valid="inputValid"
          :input-validation-message="inputValidationMessage">
        </ERTPInputValidationMsg>
        
      </form>
      
      <ERTPResultMessage/>

    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { Roadtrip } from "../models/roadtrip";
import { ERTPLocation } from "./input/ERTPLocationInput.vue";
import { GoogleService } from "../services/googleService";
import { injectRequired } from "../utilities/injectRequired";
import ERTPCreateRoadtrip from "./ERTPCreateRoadtrip.vue";
import ERTPStopInput from "./input/ERTPStopInput.vue";
import { clientOnly } from 'vike-vue/clientOnly'
const ERTPInputValidationMsg = clientOnly(() => import('../components/ERTPInputValidationMsg.vue'));

import { useUserState } from "../composables/userState";
import { useServices } from "../composables/services";
import { useCookieConsent } from "../composables/cookieConsent";
import { useProfile } from "../composables/profile";
import { useRoadtrips } from "../composables/roadtrips";
import { useRoadtripPolicy } from "../composables/roadtripPolicy";
import ERTPResultMessage from "./ERTPResultMessage.vue";

const props = defineProps<{
  defaultOriginGpid?: string,
  defaultDestinationGpid?: string
}>();

const roadtrip = defineModel<Roadtrip>();

const { userState } = useUserState(); 
const {
  profilesService,
  roadtripsService,
  timezonesService,
} = useServices();
const { accepted } = useCookieConsent();
const { profile } = useProfile();
const { roadtrips } = useRoadtrips();
const { hasRoomForNewRoadtrip } = useRoadtripPolicy(profile, roadtrips);


const googleService = injectRequired<GoogleService>('googleService');

const destinationLocation = ref<ERTPLocation>({});
const arrivalDateTime = ref<string>();
const destinationTimezoneInfo=ref();
const destinationStartTime = computed(() => {
  if (!destinationTimezoneInfo.value) return {hours: 0, minutes: 0};
  return midnightStartTime(destinationTimezoneInfo.value.currentUtcOffsetSeconds);
});
const destinationPlaceholder = computed(() => {
  return "Enter your road trip's destination"
});
const destinationDateLabelText = computed(() => {
  if (!accepted.value) return "Please confirm your cookie choices";
  return "Arrival Date (Optional)"  
});

function midnightStartTime(secondsOffset: number) {
  const hours = Math.floor(-1 * secondsOffset/3600);
  const minutes = -1 * secondsOffset % 3600;
  return { hours, minutes }
}

const originLocation = ref<ERTPLocation>({});
const departureDateTime = ref<string>();
const originTimezoneInfo = ref();
const originStartTime = computed(() => {
  if (!originTimezoneInfo.value) return {hours: 0, minutes: 0};
  return midnightStartTime(originTimezoneInfo.value?.currentUtcOffsetSeconds);
});
const originPlaceholder = computed(() => {
  return "Enter your road trip's start location";
});
const originDateLabelText = computed(() => {
  if (!accepted.value) return "Please confirm your cookie choices";
  return "Departure Date (Optional)"  
})

const loading = ref(false);

watch(originLocation, async () => {
  if (originLocation.value.latLng) {
    originTimezoneInfo.value = await timezonesService.getTimezoneInfo(originLocation.value.latLng);
  }
});

watch(destinationLocation, async () => {
  if (destinationLocation.value.latLng) {
    destinationTimezoneInfo.value = await timezonesService.getTimezoneInfo(destinationLocation.value.latLng);
  }
});

const hasBothOriginAndDestination = computed(() => {
  return hasOrigin.value && hasDestination.value;
});

// Since they're optional, having no dates or either date along is correctly ordered (true)
const hasCorrectlyOrderedDates = computed(() => {

  /*
  if (arrivalDateTime.value && departureDateTime.value) {
    return arrivalDateTime.value.getTime() >= departureDateTime.value.getTime();
  }
  */ 
  return true;
});

const inputValid = computed(
  () => {
    return hasBothOriginAndDestination.value 
      && hasCorrectlyOrderedDates.value
      && hasRoomForNewRoadtrip.value
      && accepted.value
  }
)

const origin = computed(() => {
  return {
    userProvidedName: originLocation.value.userProvidedName!,
    navigationName: originLocation.value.navigationName,
    descriptionName: originLocation.value.descriptionName,
    googlePlaceId: originLocation.value.googlePlaceId,
    googlePlaceTypes: originLocation.value.googlePlaceTypes,
    latLng: originLocation.value.latLng,
    timezone: originTimezoneInfo.value?.timezone,
    departureDateTime: departureDateTime.value ??= undefined,
  }  
});

const destination = computed(() => {
  return {
    userProvidedName: destinationLocation.value.userProvidedName!,
    navigationName: destinationLocation.value.navigationName,
    descriptionName: destinationLocation.value.descriptionName,
    googlePlaceId: destinationLocation.value.googlePlaceId,
    googlePlaceTypes: destinationLocation.value.googlePlaceTypes,
    latLng: destinationLocation.value.latLng,
    timezone: destinationTimezoneInfo.value?.timezone,
    arrivalDateTime: arrivalDateTime.value ??= undefined,
  }
})

const isReady = computed(() => {
  if (userState.value.loading) return false;
  if (googleService.isLoading.value) return false;
  if (roadtripsService.isLoading.value) return false;
  if (profilesService.isLoading.value) return false;
  if (loading.value) return false;
  return true;
});

const hasOrigin = computed(() => {
  return !!originLocation.value.userProvidedName
    && originLocation.value.userProvidedName != ''  
});

const hasDestination = computed(() => {
  return !!destinationLocation.value.userProvidedName
    && destinationLocation.value.userProvidedName != ''  
});

const inputValidationMessage = computed(() => {
  if(!accepted.value) { return "Please confirm your cookie preferences to get started." }
  if(!isReady.value) { return "Working..." }
  if(!hasRoomForNewRoadtrip.value) { return "You'll need more room to create additional road trips."  }
  if(!hasOrigin.value && hasDestination.value) { return "Please provide a start location for your road trip."}
  if(hasOrigin.value && !hasDestination.value) { return "Please provide a destination for your road trip."}
  if(!hasCorrectlyOrderedDates.value) { return "Arrival must be after departure." }
   return "Please provide a start location and destination for your road trip."
})

const canCreateRoadtrip = computed(() => {  
  return (
    isReady.value
    && hasBothOriginAndDestination.value
    && accepted.value
    && hasRoomForNewRoadtrip.value
    && hasCorrectlyOrderedDates.value
  );
})

const originGooglePlaceId = ref(props.defaultOriginGpid);
const destinationGooglePlaceId = ref(props.defaultDestinationGpid);

const originReadOnly = computed(() => { 
  return !!props.defaultOriginGpid || !accepted.value;
});
const destinationReadOnly = computed(() => { 
  return !!props.defaultDestinationGpid || !accepted.value; 
});

</script>

